import { useState, useEffect } from "react";

function App() {
  const [removeInstances, setRemoveInstances] = useState(true);
  const [removeOrdinalNumbers, setRemoveOrdinalNumbers] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [target, setTarget] = useState("");
  const [result, setResult] = useState(target);

  const convert = (target) => {
    setResult(target);
  };

  useEffect(() => {
    convert(
      target
        .split(",")
        .map((target) => {
          let newTarget = target;
          if (filterValue) {
            const regexp = /\([0-9]*\)|"/g;
            const match = target.match(regexp);
            if (match && match.length === 1) {
              const value = Number(match[0].replace('(', '').replace(')', ''));
              const expectedValue = Number(filterValue);
              console.log(value, expectedValue);
              if(value < expectedValue) {
                return null;
              }
            }
          }
          if (removeInstances) {
            const regexp = /\([0-9]*\)|"/g;
            newTarget = newTarget.replace(regexp, "");
          }
          if (removeOrdinalNumbers) {
            const regexp = /^[0-9]*\.|"/;
            newTarget = newTarget.replace(regexp, "");
          }
          return newTarget.trim().replace();
        })
        .filter(Boolean)
        .join("\n")
    );
  }, [target, removeInstances, removeOrdinalNumbers, filterValue]);

  return (
    <div className="h-screen flex flex-col bg-gray-50">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-2 px-4 flex items-center flex-wrap">
          <h2 className="mr-2 text-sm text-gray-500">
            Modifications:
          </h2>
          <button
            type="button"
            className={`inline-flex items-center mx-1 my-1 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200 ${
              removeInstances
                ? "border-indigo-500 bg-indigo-500 text-white"
                : "text-gray-700 bg-white hover:bg-gray-50"
            }`}
            onClick={() => {
              setRemoveInstances(!removeInstances);
            }}
          >
            Remove instances numbers
          </button>
          <button
            type="button"
            className={`inline-flex items-center mx-1 my-1 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200 ${
              removeOrdinalNumbers
                ? "border-indigo-500 bg-indigo-500 text-white"
                : "text-gray-700 bg-white hover:bg-gray-50"
            }`}
            onClick={() => {
              setRemoveOrdinalNumbers(!removeOrdinalNumbers);
            }}
          >
            Remove dot ordinal numbers
          </button>
          <div className="inline-flex items-center mx-1 my-1 flex">
            <label 
              htmlFor="remove-instances-below-input" 
              className="block text-sm font-medium text-gray-900 dark:text-white"
            >
              Remove instances below
            </label>
            <input 
              type="text" 
              id="remove-instances-below-input" 
              className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              onInput={(e) => setFilterValue(e.target.value)}
            />
          </div>
        </div>
      </header>
      <main className="h-full">
        <div className="h-full max-w-7xl mx-auto py-2 px-4">
          <div className="h-full w-full flex flex-col">
            <div className="h-full mb-2 py-2 px-2 bg-white shadow rounded">
              <div className="h-full flex flex-col">
                <label
                  htmlFor="target"
                  className="block text-sm font-medium text-gray-700"
                >
                  Target
                </label>
                <textarea
                  id="target"
                  name="target"
                  className="h-full w-full mt-1 block border-2 border-solid border-gray-300 rounded shadow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                  placeholder={""}
                  defaultValue={""}
                  onChange={(e) => setTarget(e.target.value)}
                />
              </div>
            </div>
            <div className="h-full mb-2 py-2 px-2 bg-white shadow rounded">
              <div className="h-full flex flex-col">
                <label
                  htmlFor="result"
                  className="block text-sm font-medium text-gray-700"
                >
                  Result
                </label>
                <textarea
                  id="result"
                  name="result"
                  className="h-full w-full mt-1 block border-2 border-solid border-gray-300 rounded shadow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                  value={result}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
